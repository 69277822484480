const InstagramIcon = (): JSX.Element => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0025 6.81354C9.16417 6.81354 6.87476 9.10295 6.87476 11.9413C6.87476 14.7796 9.16417 17.069 12.0025 17.069C14.8408 17.069 17.1303 14.7796 17.1303 11.9413C17.1303 9.10295 14.8408 6.81354 12.0025 6.81354ZM12.0025 15.275C10.1683 15.275 8.6688 13.78 8.6688 11.9413C8.6688 10.1026 10.1638 8.60758 12.0025 8.60758C13.8412 8.60758 15.3362 10.1026 15.3362 11.9413C15.3362 13.78 13.8367 15.275 12.0025 15.275ZM18.536 6.60379C18.536 7.26874 18.0005 7.79981 17.34 7.79981C16.6751 7.79981 16.144 7.26428 16.144 6.60379C16.144 5.94329 16.6795 5.40776 17.34 5.40776C18.0005 5.40776 18.536 5.94329 18.536 6.60379ZM21.9322 7.81767C21.8564 6.21552 21.4904 4.79636 20.3167 3.6271C19.1474 2.45785 17.7283 2.0919 16.1261 2.01157C14.4749 1.91785 9.52566 1.91785 7.87443 2.01157C6.27675 2.08744 4.85758 2.45339 3.68387 3.62264C2.51015 4.79189 2.14867 6.21106 2.06834 7.8132C1.97462 9.46444 1.97462 14.4137 2.06834 16.0649C2.1442 17.667 2.51015 19.0862 3.68387 20.2555C4.85758 21.4247 6.27229 21.7907 7.87443 21.871C9.52566 21.9647 14.4749 21.9647 16.1261 21.871C17.7283 21.7951 19.1474 21.4292 20.3167 20.2555C21.4859 19.0862 21.8519 17.667 21.9322 16.0649C22.0259 14.4137 22.0259 9.4689 21.9322 7.81767ZM19.799 17.8366C19.4509 18.7113 18.777 19.3852 17.8979 19.7378C16.5813 20.2599 13.4574 20.1394 12.0025 20.1394C10.5476 20.1394 7.41922 20.2555 6.10716 19.7378C5.23246 19.3897 4.55857 18.7158 4.20601 17.8366C3.68387 16.5201 3.80436 13.3962 3.80436 11.9413C3.80436 10.4864 3.68833 7.358 4.20601 6.04594C4.55411 5.17123 5.22799 4.49735 6.10716 4.14479C7.42369 3.62264 10.5476 3.74314 12.0025 3.74314C13.4574 3.74314 16.5858 3.6271 17.8979 4.14479C18.7726 4.49289 19.4464 5.16677 19.799 6.04594C20.3212 7.36246 20.2007 10.4864 20.2007 11.9413C20.2007 13.3962 20.3212 16.5246 19.799 17.8366Z"
          fill="#10247E"
        />
      </svg>
    </div>
  );
};

export default InstagramIcon;
